import { ListenerSignature } from 'tiny-typed-emitter';
import { BaseTypedEmitter } from '../BaseTypedEmitter';

export abstract class DataObject<
  T,
  K extends ListenerSignature<K> = ListenerSignature<unknown>,
> extends BaseTypedEmitter<K> {
  protected data: T | null;
  protected _id: string;
  loaded: boolean = false;

  constructor(id: string) {
    super();
    this._id = id;
    this.data = null;
  }

  get id(): string {
    return this._id;
  }

  get(): T | null {
    return this.data;
  }

  getWithPath(thisPath?: any) {
    let data: any;
    const selectedData = this.data;
    if (selectedData != null) {
      data = selectedData;
      if (thisPath) {
        for (let index = 0; index < thisPath.length; index++) {
          const key: string | number = thisPath[index];
          data = data ? data[key] : undefined;
        }
      }
    }
    return data;
  }

  async fetch(): Promise<DataObject<T, K>> {
    this.loaded = true;
    return this;
  }

  dispose(): void {
    super.destroy();
  }
}
